import React from "react";
import Layout from "../layouts";
import { graphql } from "gatsby";
import CsCardGallery from "../components/caseStudies/csCardGallery";
import SEO from "../components/seo";

const CaseStudies = ({ data }) => {
  const { nodes } = data.allContentfulCaseStudiesPage;
  const cg = nodes.filter(node => node.sectionTitle === "01_Case_Studies - Card Gallery");
  return (
    <Layout>
      <SEO
        description="Learn more about all our success stories with clients across industry verticals & industries within eCommerce, SaaS, and B2B."
        title="Growth Marketing Case Studies | Full Funnel Growth"
      />
      <CsCardGallery content={cg}/>
    </Layout>
  );
};

export default CaseStudies;

export const query = graphql`
query caseStudiesQuery {
  allContentfulCaseStudiesPage {
    nodes {
      sectionTitle
      bodyText {
        text
      }
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
      cardComponents {
        references {
          caseName
          caseImages {
            gatsbyImageData(
              quality: 100,
              placeholder: BLURRED
            )
            title
            url
          }
          caseText {
            text
          }
        }
      }
    }
    totalCount
  }
}

`;