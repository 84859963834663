import React from "react";
import styled from "styled-components";
import CsCard from "./csCard";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Background from "../background";

const CardGalleryWrapper = styled.div`
  display:flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* padding-top: 10rem; */
  

  .text__container{
    position: relative;
    width: 100%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10rem;
    @media only screen and (max-width: 768px){
      padding-top: 3rem;
      align-items: center;
    }
  }

  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: var(--orange);
    width:100%;
    text-align: left;
    padding: 0;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 768px){
      text-align: center;
      padding: 0;
    }
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    width:100%;
    text-align: center;
    padding: 0;
    margin-bottom: 2.5rem;
    color: var(--blue);

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
      text-align: left;
    }

  }

  .card_layout{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    place-items: center;
    width: 95%;
    margin-bottom: 3rem;
    position: relative;
    z-index: 0;

    /* @media only screen and (max-width: 1024px){
      width: 90%;
    } */
    
    @media only screen and (max-width: 1024px){
      display:flex;
      flex-direction: column;
      align-items: center;
 
      /* padding: 0 3rem; */
      row-gap: 4rem;

    }
  }

  @media only screen and (max-width: 768px){
    /* padding-top: 3rem; */
    align-items: center;
  }

  /* @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  } */

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 1024px){
    margin-left: 4rem;
  }

  @media only screen and (max-width: 768px){
    margin-left: 2rem;
    width: auto;
  }
`;

const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 26%;
  height: 55%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }

  @media only screen and (max-width: 768px){
    display:none;
  }

`;

const CsCardGallery = ({ content }) => {
  const { cardComponents, bodyText, orangeBackgroundRectangle } = content[0];
  return (
    <CardGalleryWrapper>
      <div className="text__container">
        <Background/>
        <h1>{bodyText[0].text}</h1>
        <h4>{bodyText[1].text}</h4>
      </div>
      
      <OrangeDesktopBackground>
        <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
      </OrangeDesktopBackground>
      <div className="card_layout">
        {cardComponents.references.map((companyCard, index) => {
          return (
            <CsCard card={companyCard} key={index}/>
          );
        })}
      </div>
    </CardGalleryWrapper>
  );
};

export default CsCardGallery;