import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import slugify from "slugify";

const OuterWrapper = styled.div`
  .card{
    text-decoration: none;
    transition: transform 500ms ease;
    margin-bottom: 2rem;
  }

  .card:hover{
    transform: scale(1.05);
  }
`;

const CsCardWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  height:1000px;
  max-width: 426px;
  text-decoration: none;

  .card_banner__container{
    width: 100%;
  }

  .card_banner_image{
    width: 100%;
    height: 100%;
    max-height: 304px;
  }

  .card_logo__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 200px;
    /* padding: ${({ name }) => name.toLowerCase() === "andrea gomez" ? "0 1rem" : "0 1rem"}; */

    @media only screen and (max-width: 500px){
      margin-bottom: 2rem;
    }
  }

  .card_bottom__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }

  .card_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 139.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    padding: 0 2.5rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: 768px){
      font-size: 18px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 3rem;
      margin-bottom: 3rem;
      padding: 0 4rem;
    }
  }

  .card_button{
    margin-bottom: 2rem;

    @media only screen and (max-width: 768px){
      font-size: 18px;
      width: auto;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    max-width: 540px;
    height: auto;
  }

  @media only screen and (max-width: 1024px){
    min-height: 800px;
  }

  @media only screen and (max-width: 500px){
    min-height: auto;
    height: auto;
  }

`;

const CsCard = ({ card }) => {
  const { caseName, caseText, caseImages } = card;
  const slug = slugify(caseName, { lower: true });
  return (
    <OuterWrapper>
      <Link className="card" name={caseName} to={`/case-studies/${slug}`}>
        <CsCardWrapper>
          {/* image */}
          <div className="card_banner__container">
            <GatsbyImage alt={caseImages[0].title} className="card_banner_image" image={getImage(caseImages[0].gatsbyImageData)}/>
          </div>
          {/* logo */}
          <div className="card_logo__container">
            <GatsbyImage alt={caseImages[1].title} className="card_logo" image={getImage(caseImages[1].gatsbyImageData)}/>
          </div>
          <div className="card_bottom__container">
            <p className="card_text">{caseText[0].text}</p>
            <button className='hire_button card_button'>{caseText[1].text}</button>
          </div>
      
        </CsCardWrapper>
      </Link>
    </OuterWrapper>
    
  );
};

export default CsCard;